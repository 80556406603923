<template>
  <div class="form-group"  :class="{'hide': (!props.itemModel.isShown)}" v-if="ShowControl()">
        <label :for="props.itemModel.id" class="contact-form-label">
            {{ labelText }}
			<span v-if="!isRequired" class="optional-text"> (optional)</span>
		</label>
        <div :id="props.itemModel.id + '-dropdown'" class="dropdown">
            <template v-if="finishLoaded">
                <!-- BEGIN Lookup Control https://stevencotterill.com/articles/how-to-build-an-autocomplete-field-with-vue-3 -->
                <template v-if="isOverideLookup()"> <!-- v-if="lookupItems.length > 50"> -->
                    <div class="lookup-form-input contact-form-dropdown-selection dropdown-selected" >
                    <input type="text" 
                        :id="props.itemModel.id" 
                        :key="filteredTerm.value" 
                        class="contact-form-input" 
                        placeholder="Start typing..." 
                        v-model="filteredTerm"
                        :validator="validation"
                        :validator-rule-id="props.itemModel.id"
                        v-on:blur="handleBlur"
                        v-on:focus="handleFocus"
                        v-on:keyup="handleKeyPress()"/>
                    </div>
                   <template v-if="showLookupList()">
                        <ul :id="props.itemModel.id + '-list'" class="gibs-filter-list" >
                            <li class="gibs-form-dropdown-item disabled-input">
                                <span>
                                    -- Please select --
                                </span>
							</li>
                            <li class="gibs-form-dropdown-item"
                                v-for="item in filteredItems"
                                :key="item.Value"
                                @click="handleChange(item)">
                                <span>
                                    {{ item.Text }}
                                </span>
                            </li>
                        </ul>
                    </template>
                    <template v-else-if="displayNoLookupResult">
                        <ul class="gibs-filter-list">
                            <li class="gibs-no-match-list">
                                <span>
                                    No match found
                                </span>
                                <span v-if="displayAddNewCompanyButton">
                                    <gibsControlPrimaryButton @click="addNewCompany" :primaryButtonSpec="addNewCompanyButtonSpec" />
                                </span>
                                <span v-if="displayAddNewDivisionButton">
                                    <gibsControlPrimaryButton @click="addNewDivision" :primaryButtonSpec="addNewDivisionButtonSpec" />
                                </span>
                            </li>
                        </ul>
                    </template>
                    <p :id="`${props.itemModel.id}-error`" class="gibs-form-group-error gibs-small-regular"></p>
                    <p class="hint-text">{{ hint }}</p>
                </template>
                <!-- BEGIN Lookup Control -->
                <div v-else>
                    <GibsControlFormDropdown 
                        :key="props.itemModel.id" 
                        ref="{{props.itemModel.id}}"
                        :input-id="props.itemModel.id" 
                        labelText=""
                        :options="dropDownOptions()" 
                        :selectedOption="getlookupOptionValue(props.itemModel.value)"
                        :show="props.itemModel.isShown"
                        :isRequired="isRequired"
                        @dropdownSelection="formDropDownChange(props.itemModel, $event)">
                    </GibsControlFormDropdown>
                </div>
            </template>
            <span v-if="isLoading" class="button-spinner-container">
                    <i>Loading...</i><span class="loader ml-2"></span>
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { z } from 'zod';
    import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS
    import { useNotificationStore } from '~/stores/notification';
    import { CRMAddCompanyAttributeKey, Guid } from '~/utils/enums';
    import * as lodash from 'lodash';

    const store = useFormsStore();
    const notificationStore = useNotificationStore();
    const { dynamicWebFormValidation } = useValidations();
    const lookupItems = ref<Array<IFormTypeAheadItem>>(new Array<IFormTypeAheadItem>());
    const inputValue = ref<IFormTypeAheadItem>("");
    const displayNoLookupResult = ref(false);
    const isLoading = ref<boolean>(false);
    const loadingRef = ref<typeof GibsControlLoading>();
    const finishLoaded = ref(false);
    const filteredTerm = ref('');
    const selectedItem = ref<IFormTypeAheadItem>();
    const shouldShowList = ref(false); 
    const groupBy = lodash.groupBy;
    const isFocused = ref(false);
    let validation = ref<InstanceType<typeof Validation> | null>(null);

    
    function getlookupOptionValue(){
        let findItem = dropDownOptions().find(_ => _['selected'] == true);
        if (findItem)
        {
            return findItem['id'];
        }
        return '';
    }

    function checkDisplay(checkID: string) : boolean {
        let display = false;
        if (props.itemModel.id === checkID){
            for (const field in CRMAddCompanyAttributeKey) {
                if (props.itemModel.Atttribute === CRMAddCompanyAttributeKey[field.toLocaleLowerCase()]) {
                    display = true;
                }
            }
        } 
        return display;
    }
    
    const displayAddNewCompanyButton = computed (() => {
        return isOrganizationLookup(props.itemModel);
    })

    const displayAddNewDivisionButton = computed (() => {
        return isDivisionLookup(props.itemModel);
    })



    const addNewCompanyButtonSpec: IControlButton = {
        buttonElName: "gibs-add-new-company-button",
        buttonText: "Add new company",
        class: "gibs-add-new-company-button",
        enableUrl: false
    }

    const addNewDivisionButtonSpec: IControlButton = {
        buttonElName: "gibs-add-new-company-button",
        buttonText: "Add new Division",
        class: "gibs-add-new-company-button",
        enableUrl: false
    }
    

    const formSection = defineModel({
        type: Object as () => IFormSection,
        required: true,
    });

    const emitFormLookup = defineEmits<{
		lookupSelection: [value: IFormTypeAheadItem |  null],
        newItem: [value: IFormTypeAheadItem |  null]
	}>();

    const currentField = computed(() => {
        return formSection.value.Fields.find(_ => _.FieldId == props.itemModel.id);
    });
    const labelText = computed(() => {
        return currentField.value.Label ??  props.itemModel.labelText 
    });
    const isRequired = computed(() => {
        return (currentField.value?.Validator??"").toLocaleLowerCase() === 'display as mandatory' ?? false
    });

    const minLength = computed(() => {
        return minInputLength(props.itemModel);
    });
    
    function isOverideLookup() {
        return (isOrganizationLookup(props.itemModel) || isDivisionLookup(props.itemModel));
    }

    const hint = computed(()=>{
        return "";
    })

    const props = defineProps({
        itemModel: {
            type: Object as () => z.infer<typeof FieldInfoItem>,
            required: true,
        },
        validator: {
            type: Validation,
            default: null,
        },
        doValidation :
        {
            type: Boolean,
            default: false
        },
        validatorRuleId: {
            type: String,
            default: null
        },
        inputId: {
            type: String,
            required: true,
        },
        awaitOptions: {
            type: Function,
            default: async () => { return Promise<[string, string, Array<IFormTypeAheadItem>]> }
		},
        ExternalDataSource : {
            type: Function,
            default: (entity : z.infer<typeof FieldInfoItem>) => { return [] }
        },
        refreshKey : {
            type: Number,
            default: 0
        },
        useAwaitRefresh : {
            type: Boolean,
            default: false
        },
        formLevel : {
            type: String,
            required : true
        },
        alternativeLookup : {
            type: Function,
            default: (search : string, inputId: string) => { return '' }
        }
    });

    validation.value = dynamicWebFormValidation(store.SelectedFormSection!.Fields, formSection.value.Fields);

    watch(() => isFocused.value, (newValue)=>{

        if ((selectedItem.value == null || filteredTerm.value == '') && minLength.value == 0){
            shouldShowList.value = true;
        }
        else{
            //shouldShowList.value = false;
        }
    })

    watch(() => filteredTerm.value, (n)=>{
        if ((selectedItem.value == null || filteredTerm.value == '') && minLength.value == 0){
            shouldShowList.value = true;
        }
        else{
            shouldShowList.value = false;
        }
        console.log(`${shouldShowList.value} ************ filteredTerm.value *********** ${filteredTerm.value}`)
    });

    function minimiseAlldropdownList() {
        isFocused.value = false;
        displayNoLookupResult.value = false;
        document.removeEventListener("click", onFocusOnBlurEvent);
    }

    function onFocusOnBlurEvent(evt: any) {
        
        const listEl = document.getElementById(`${props.itemModel.id}-dropdown`);
        let targetEl = evt.target as HTMLElement; // clicked element
        let exists = false;
        do {
            if (targetEl == listEl) {
                // when clicking the input element, focus is true
                isFocused.value = true;
                exists = true;
                return;
            }
            if (targetEl) {
                targetEl = targetEl!.parentNode;
            }
        } while (targetEl);

            // while targetEl does not exist, then it means outside element is clicked. 
            // Remove event listener and minimise all selection lists inlcuding "no match found" message
            minimiseAlldropdownList();
    }

    function handleBlur(){
        // not needed since the eventlistener deals with the outside click
    }

    function handleKeyPress() {
        isFocused.value = true;
        showLookupList();
    }

    function handleFocus(){
        isFocused.value = true;
        showLookupList();
        document.addEventListener("click", onFocusOnBlurEvent);
    }

    // function handleClick(){
    //     if ((selectedItem.value == null || filteredTerm.value == '') && minLength.value == 0){
    //         shouldShowList.value = true;
    //     }
    //     else{
    //         shouldShowList.value = false;
    //     }
    // }
    
    const filteredItems = computed(() => {
        if (shouldShowList.value == false 
            && (filteredTerm.value == undefined || filteredTerm.value == '' 
                || filteredTerm.value.length < minLength.value || lookupItems.value.length == 0))
        {
            displayNoLookupResult.value = false;
            return [];
        }
        else
        {
            let returnCollection = lookupItems.value.filter((item) => 
                        {
                            var isFound = item.Text.trim().toLowerCase().startsWith(filteredTerm.value.trim().toLowerCase());
                            if (!isFound && item.ShortText != null) {
                                isFound = item.ShortText?.trim().toLowerCase().startsWith(filteredTerm.value.trim().toLowerCase());
                            }

                            if (!isFound && isGuid(filteredTerm.value)) {
                                isFound = item.Value.trim().toLowerCase().startsWith(filteredTerm.value.trim().toLowerCase());
                            }
                            return isFound;
                        });

            displayNoResultComponent();
            let filterReturnCollection = [];
            // HACK TO REMOVE DUPLICATES
            let groupedFields = groupBy(returnCollection, 'Value');
            for (const [key, value] of Object.entries(groupedFields)) {
                filterReturnCollection.push(value[0]);
            }

            // if no filtered collection, hide the selectionList and display the no lookup result
            isFocused.value = (filterReturnCollection.length === 0)? false : true;
            displayNoLookupResult.value = !isFocused.value;

            return filterReturnCollection;
        }
    });

    function formDropDownChange(formField: z.infer<typeof FieldInfoItem>, input: IDropdownItem) {
        if (props.itemModel.value['Value'] != input['id']) {
            let item = `{ "Text": "${input['itemText']}", "Value" : "${input['id']}" }`
            handleChange(item);
        }
    }

    function dropDownOptions(){
        let items = lookupItems.value.map(_ => ({'id' : _.Value, 'itemText' : _.Text, 'selected' : (_.Value == props.itemModel.value['Value'] ? true : false)}));
        return items;
    }

    function showLookupList(){
        console.log(`showLookupList ${isFocused.value}`)
        if (isFocused.value == false){
            return false;
        }
        if (filteredItems.value == undefined || filteredItems.value == null)
        {
            return false;
        }

        if (props.itemModel.value['Text'] == filteredTerm.value && filteredTerm.value == (selectedItem.value != undefined ? selectedItem.value['Text'] : 'NA'))
        {
            // if there's an exact match, still display the selection list
            shouldShowList.value = true;
            return true;
        }

        if ((selectedItem.value == null || filteredTerm.value == '') && minLength.value == 0){
            shouldShowList.value = true;
        }

        if (shouldShowList.value == true){
            return true;
        }

        let showlookup = filteredItems.value.length > 0;
        if (showlookup)
        {    
            try
            {
                if (filteredItems.value.length > 0 && selectedItem.value != null && props.itemModel.value == selectedItem.value && filteredTerm != selectedItem.value)
                {
                    showlookup = true;
                }
                else if (filteredItems.value.length == 1 && props.itemModel.value != '' && filteredItems.value[0].Text.toLowerCase() == (props.itemModel.value['Text']??"").toLowerCase())
                {
                    showlookup = false;
                }
                else if (selectedItem.value != null && props.itemModel.value != '' && props.itemModel.value == selectedItem.value && filteredTerm.value == selectedItem.value['Text'])
                {
                    showlookup = false;
                }
                else if (filteredItems.value.length == 1 && props.itemModel.value != '' && filteredItems.value[0].Text.toLowerCase() == (props.itemModel.value['Text']??"").toLowerCase())
                {
                    showlookup = false;
                }
                else if (selectedItem.value != null && props.itemModel.value != '' && props.itemModel.value == selectedItem.value && filteredTerm.value == selectedItem.value['Text'])
                {
                    showlookup = false;
                }
                // else{
                //     debugger;
                // }
            }
            catch(ex){
                showlookup = false;
                debugger;
            }
        }
    
        return showlookup;
    }

    async function getLookupDataPost(entity : CRMEntity, attribute : string, searchValue : string) : Promise<Array<IFormTypeAheadItem>> {
        isLoading.value = true;
        let lookupList: Array<IFormTypeAheadItem> = await props.ExternalDataSource(props.itemModel);
        try
        {
            if (lookupList.length == 0)
            {
                const response = await store.fetchLookupData(entity, attribute, searchValue);

                if (response != null)
                {
                    isLoading.value = false;
                    lookupList = response;
                    return lookupList;
                }
            }
        }
        catch{
            isLoading.value = false;
        }

        return lookupList;
    }

    function ShowControl(){
        if (props.itemModel.id === "gibs_customerdivision_brite_05_04_divisionasontheinvoice"){
            if (props.useAwaitRefresh === true)
            {
                return true;
            }
            else
            {
                return false;
            }
            
        }
        else
            return true;
    }

    function ApplyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
        const inputElement = document.getElementById(`${props.inputId}`) as HTMLElement;
        const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;
        if (inputElement != null)
        {
            if (isInvalid) {
                inputElement.classList.add("has-error");
            }
            else {
                inputElement.classList.remove("has-error");
            }
        }
        if (errorElement != null)
        {
            let errorString = '';
            if (typeof (props.validator) !== 'undefined' && props.validator != null) {
                errorString = props.validator.getValidationResultString(props.validatorRuleId);
            }
            errorElement.innerText = errorString;
        }
    }

    function handleChange(item: IFormTypeAheadItem | null) {
        if (typeof item == 'string' && item.startsWith("{")){
            item = JSON.parse(item);
        }
        props.itemModel.value = item;
        selectedItem.value = item
        filteredTerm.value = props.itemModel.value['Text'];
        props.validator.setValidationRuleSourceValue(props.validatorRuleId, selectedItem.value['Text']);
        let resultList = props.validator.validateRule(props.validatorRuleId);
        emitFormLookup("lookupSelection", item);
        
        nextTick(() => {
            setTimeout(() => {
                ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
            }, 100);
        });

        // minimise the dropdownlist after selection
        minimiseAlldropdownList();
    };

    // nextTick awaits until the changes reach DOM. However, sometimes one nextTick is not enough for DOM to update yet. So added a count to allow multiple ticks 
    async function nextTickCount(count: number) {
        let i = 0;
        while (i <= count) {
            await nextTick();
            i++;
        }
    }

    // Set the ID to placeholder GUID only for both company and division
    async function createNewCompanyAPI(companyName : string){
        return Guid.DeadBeef;
    }

    // Set the ID to placeholder GUID only for both company and division
    async function createNewDivisionAPI(companyID : string, divisionName : string){
        return Guid.DeadBeef;
    }



    function displayNoResultComponent() {
        let checkValue = filteredTerm.value;
        if (checkValue['Text'] != null){
            checkValue = checkValue['Text'];
        }

        var containsItem = lookupItems.value.filter((item) => {
            var isFound = item.Text.trim().toLowerCase().indexOf(checkValue.trim().toLowerCase()) > -1;
            if (!isFound) {
                isFound = item.Value.trim().toLowerCase().indexOf(checkValue.trim().toLowerCase()) > -1;
            }

            return isFound;
        });
        
        if (containsItem.length == 0) {
            displayNoLookupResult.value = true;
        }
        else {
            displayNoLookupResult.value = false;
        }
    }
    

    async function getLookupData(entity : CRMEntity, attribute : string, searchValue : string){
        isLoading.value = true;
        await getLookupDataPost(entity, attribute, searchValue).then(
            (data: Array<IFormTypeAheadItem>) => {
                    lookupItems.value = data;
                    return lookupItems.value;
        })
        .catch((error) =>{
            let notification: INotification = {
                    type: "error",
                    title: "",
                    body: error.message,
                    date: new Date(),
                    timeout: true,
                    timer: 10000
                };
            notificationStore.addNotification(notification);
            lookupItems.value = [];
        })
        .finally(() =>{
            isLoading.value = false;
        });
    }

    function IsJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const primaryKeyForAwaitOptions = ref("");
    async function LoadData()
    {
        let propValue: object | string | null | undefined;
        if (typeof props.itemModel.value === 'object') {
            propValue = props.itemModel.value;
        } else if (isGuid(props.itemModel.value)) {
            if (props.itemModel.value === Guid.Empty) {
                // It came in empty from CRM or was never set.
                propValue = `{ "Text": "", "Value" : "${Guid.Empty}", "IsNew": false }`;
            }
            else
            {
                propValue = await store.fetchLookupDataById(currentField.value.Atttribute, props.itemModel.value);
                // TODO : Not really sure how to handle this right now, there is a GUID value, bit not found in the lookup values we have from CRM
                if (propValue === undefined){
                    propValue = `{ "Text": "", "Value" : "${props.itemModel.value}", "IsNew": true }`;
                }
            }
        } else if (IsJson(props.itemModel.value)) {
            propValue = JSON.parse(props.itemModel.value);
        } else {
            propValue = JSON.parse(`{ "Text": "${props.itemModel.value}", "Value" : "${Guid.Empty}" }`);
        }
        
        if (props.useAwaitRefresh === true)
        {
            isLoading.value = true;
            lookupItems.value = [];
            //props.itemModel.value = Guid.Empty;
            let results = await props.awaitOptions();
            let additionalText =  "";
            if (results.length > 1){
                primaryKeyForAwaitOptions.value = results[0];
                additionalText = results[1];
                lookupItems.value = results[2]
            }
            if (lookupItems.value.length === 0){
                lookupItems.value.push({ "Text": `No ${labelText.value}`, "Value" : `${Guid.Empty}`, "IsNew": false });
            }
            isLoading.value = false;
        }
        else if (props.itemModel.id != "gibs_customerdivision_brite_05_04_divisionasontheinvoice")
        {		
            try{
                if (minLength.value < 10){
                    if (currentField.value.AlternativeAtttribute == undefined){
                        await getLookupData(currentField.value.Entity, currentField.value.Atttribute, propValue);
                    }
                    else{
                        await getLookupData(currentField.value.AlternativeEntity, currentField.value.AlternativeAtttribute, propValue);
                    }
                }
                else if (lookupItems.value.length == 0)
                {
                    lookupItems.value = [propValue];
                }
            }
            catch(ex){
                debugger;
            }
        }
        
        if (propValue.Text === propValue.Value && isGuid(propValue.Value)){
            inputValue.value =  lookupItems.value.find(_ => _.Value == propValue.Value)?.Text ?? propValue.Text;
        }
        else{
            inputValue.value =  lookupItems.value.find(_ => _.Text == propValue.Text && _.Value == propValue.Value)?.Text ?? propValue.Text;
        }
        if (inputValue.value == Guid.DeadBeef){
            inputValue.value = "";
        }
        else if (isGuid(inputValue.value)){
            inputValue.value = "";
        }
        else if (inputValue.value == null){
            inputValue.value = "";
        }

        if (props.doValidation)
        {
		    if (typeof (props.validator) !== 'undefined' && props.validator != null) {
                props.validator.setValidationRuleSourceValue(props.validatorRuleId, inputValue.value);
                let resultList = props.validator.validateRule(props.validatorRuleId);
                await nextTickCount(3);
                ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
            }
	    }
        finishLoaded.value = true;
	}

    function setFilterTerm(){
        if (filteredTerm.value == '')
        {
            if (isGuid(props.itemModel.value) && props.itemModel.value != Guid.Empty && lookupItems.value.length > 0){
                let findItem = lookupItems.value.find(_ => _.Value == props.itemModel.value);
                if (findItem != null){
                    props.itemModel.value = findItem;
                    filteredTerm.value = findItem.Text;
                    selectedItem.value = findItem;
                }
                else{
                    filteredTerm.value = '';
                }
            }
            else if (typeof props.itemModel.value == 'string' && props.itemModel.value.startsWith("{")){
                let lookupItem = JSON.parse(props.itemModel.value);
                let findItem = lookupItems.value.find(_ => _.Value == lookupItem['Value'])
                if (findItem == null){
                    filteredTerm.value = props.alternativeLookup(lookupItem['Value'], props.itemModel.id);
                    if (filteredTerm.value != ''){
                        props.itemModel.value = { "Text": filteredTerm.value, "Value" : lookupItem['Value'], "IsNew": true };
                        findItem = lookupItems.value.find(_ => _.Value == lookupItem['Value']);
                        if (findItem == null){
                            lookupItems.value.push(props.itemModel.value);
                        }
                        findItem = props.itemModel.value;
                    }
                }
                else{
                    props.itemModel.value = findItem;
                }

                if (findItem != null)
                {
                    selectedItem.value = findItem;
                    filteredTerm.value = findItem['Text'];
                }
                else
                {
                    props.itemModel.value = '';
                    filteredTerm.value = '';
                    selectedItem.value = null;
                }
            }
            else if (typeof props.itemModel.value == 'object' && props.itemModel.value.Value != Guid.Empty){
                filteredTerm.value = props.itemModel.value.Text;
                selectedItem.value = props.itemModel.value;
                let findItem = lookupItems.value.find(_ => _.Value == props.itemModel.value);
                if (findItem == null){
                    lookupItems.value.push(props.itemModel.value);
                }
            }
            else if (typeof props.itemModel.value == 'string' && props.itemModel.value == Guid.Empty)
            {
                props.itemModel.value = '';
                filteredTerm.value = '';
                selectedItem.value = null;
            }
            else if (typeof props.itemModel.value === 'string' && props.itemModel.value != '')
            {
                let findItem = lookupItems.value.find(_ => _.Text == props.itemModel.value);
                if (findItem != null){
                    props.itemModel.value = findItem;
                    selectedItem.value = findItem;
                    filteredTerm.value = findItem.Text;
                }
                else{
                    filteredTerm.value = props.itemModel.value;
                    selectedItem.value = props.itemModel.value;
                }
            }
        }
    }

    onMounted(async () => {
        finishLoaded.value = false;
        loadingRef.value = store.getLoader().value
        await LoadData();
        setFilterTerm();
        
        props.validator.setValidationRuleSourceValue(props.validatorRuleId, selectedItem.value != null ? selectedItem.value['Text'] : "");
        let resultList = props.validator.validateRule(props.validatorRuleId);
        await nextTickCount(3);
        ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
    });


    async function addNewDivision() {
        if (filteredTerm.value) {
            await loadingRef.value.LoadData(async (request:any) =>
            {
                //displayAddNewCompanyButton.value = false;
                displayNoLookupResult.value = false
                let item: IFormTypeAheadItem = {} as IFormTypeAheadItem;
                item.Text = filteredTerm.value;
                request.status = 'createNewDivisionAPI';
                item.Value = await createNewDivisionAPI(primaryKeyForAwaitOptions.value, filteredTerm.value);
                item.IsNew = true;
                lookupItems.value.push(item);
                props.itemModel.value = JSON.stringify(item)

                emitFormLookup("newItem", item);
                let notification: INotification = {
                    type: "success",
                    title: "New division added:",
                    body: `You have added a new division: ${item.Text}.`,
                    date: new Date(),
                    timeout: true,
                    timer: 10000
                };
                notificationStore.addNotification(notification);
                //store.addFormTypeAheadItemsStore(props.inputId, item);
                handleChange(item);
            }); 
        }
    }

    async function addNewCompany() {
        if (filteredTerm.value) {
            await loadingRef.value.LoadData(async (request:any) =>
            {
                //displayAddNewCompanyButton.value = false;
                displayNoLookupResult.value = false
                let item: IFormTypeAheadItem = {} as IFormTypeAheadItem;
                item.Text = filteredTerm.value;
                request.status = 'createNewCompanyAPI';
                item.Value = await createNewCompanyAPI(filteredTerm.value);
                item.IsNew = true;
                lookupItems.value.push(item);
                props.itemModel.value = JSON.stringify(item);
            
                emitFormLookup("newItem", item);
                let notification: INotification = {
                    type: "success",
                    title: "New company added:",
                    body: `You have added a new company: ${item.Text}.`,
                    date: new Date(),
                    timeout: true
                };
                notificationStore.addNotification(notification);
                //store.addFormTypeAheadItemsStore(props.inputId, item);
                handleChange(item);
            });
        }
    }

    watch(() => props.refreshKey, async () => {
        filteredTerm.value = '';
        selectedItem.value = null;
		await LoadData();
	});
</script>

<style>
/* .form-group-container .simple-typeahead-list {
    background-color: white;
    border-radius: 8px;
    border: none !important;
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
}

.form-group-container.simple-typeahead-list > * {
    border-left: none !important;
    border-right: none !important;
}
.form-group-container .simple-typeahead .simple-typeahead-list > *:first-child {
    color: white;
    background-color: #002C77 !important;
}

.form-group-container .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.simple-typeahead-list-item-active:first-child {
    color: white;
    background-color: #002C77 !important;
}

.form-group-container .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.simple-typeahead-list-item-active {
    color: white;
    background-color: #B3C0D6 !important;
}
.form-group-container .simple-typeahead .simple-typeahead-list .simple-typeahead-list-item.simple-typeahead-list-item-active:hover {
    background-color: #B3C0D6 !important;
} */
</style>